import { useId, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import './_radio.css';

const Radio = ({ className, label, ...props }) => {
	const [effect, setEffect] = useState(false);
	const id = useId();

	return (
		<div className={`inline-flex items-center relative ${className}`}>
			<div
				className={cx(
					'absolute',
					'h-7 w-7',
					'left-0',
					'top-0',
					'rounded-full',
					'bg-black-o-20',
					{
						'animate-ping': effect
					}
				)}
				style={{ animationIterationCount: 1 }}
				onAnimationEnd={() => setEffect(false)}
			/>

			<input
				type="radio"
				id={id}
				onClick={() => setEffect(true)}
				className={cx(
					'relative',
					'radio-after',
					'appearance-none',
					'duration-300',
					'transition-all',
					'h-7 w-7',
					'rounded-full',
					'bg-white',
					'border',
					'border-gray-300',
					'checked:border-black',
					'checked:border-[0.875rem]',
					'inline-flex',
					'items-center',
					'justify-center',
					'cursor-pointer',
					'disabled:cursor-not-allowed',
					'disabled:opacity-60',
				)}

				{...props}
			/>

			{label != null ?
				<label
					htmlFor={id}
					children={label}
					className="ml-2 cursor-pointer"
					onClick={() => setEffect(true)}
				/>
				: null}
		</div>
	);

};

Radio.defaultProps = {
	className: '',
	disabled: false,
	label: null,
};

Radio.propTypes = {
	className: PropTypes.string,
	disabled: PropTypes.bool,
	label: PropTypes.string,
};

export default Radio;