import { List, Datagrid, TextField, DateField, BooleanField } from 'react-admin';

export const StrollerList = () => (
	<List>
		<Datagrid>
			<TextField source="strollerId" />
			<TextField source="name" />
			<DateField source="publishedStart" />
			<TextField source="category" />
			<BooleanField source="commentable" />
		</Datagrid>
	</List>
);