import {
	Datagrid,
	TextField,
	SimpleForm,
	TextInput,
	Edit,
	Create,
	SelectInput,
	EditButton,
	ReferenceInput,
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import { PaginatedList } from './Pagination';
import { ImageInput } from './ImageInput';
import { MetaInput } from './MetaInput';
import { ProductsInput } from './ProductsInput';

const AccessoryForm = () => (
	<SimpleForm>
		<TextInput
			source="type"
			defaultValue="ACCESSORY"
			disabled={true}
			sx={{ display: 'none' }}
		/>

		<TextInput label="Title" source="title" fullWidth />

		<ReferenceInput label="Brand" source="brandId" reference="brands">
			<SelectInput optionText="name" fullWidth />
		</ReferenceInput>

		<SelectInput
			source="paymentMethod"
			defaultValue="RESURS"
			emptyValue="RESURS"
			emptyText="Resurs"
			choices={[
				{ id: 'STROLLY', name: 'Strolly' },
			]}
			fullWidth
		/>

		<ImageInput />

		<ProductsInput />

		<MetaInput />
	</SimpleForm>
);

export const AccessoriesList = () => (
	<PaginatedList title="Accessories list">
		<Datagrid>
			<TextField source="id" />
			<TextField source="title" />
			<EditButton />
		</Datagrid>
	</PaginatedList>
);

export const AccessoryEditForm = () => (
	<Edit redirect={false}>
		<AccessoryForm />
	</Edit>
);

export const AccessoryCreateForm = () => (
	<Create>
		<AccessoryForm />
	</Create>
);
