import { httpClient } from "lib/http";
import { getApiUrl } from 'lib/env';

const apiUrl = `${getApiUrl()}api/v1`;

export const handleMeta = (resource, params) => new Promise(async (resolve) => {
	let newMeta = [];
	let changedMeta = [];
	let deletedMeta = [];

	if (params.data?.metas != null) {
		newMeta = params.data.metas.filter(
			p => p.id == null
		);
	}

	if (params.data?.metas != null && params.previousData?.metas != null) {
		params.previousData.metas.forEach((meta) => {
			const foundInCurrentData = params.data.metas.findIndex((currentMeta) =>
				currentMeta.id == meta.id
			);

			if (foundInCurrentData == -1) {
				// This meta object isnt in current data (deleted)
				deletedMeta.push(meta);
			} else {
				// This meta still exists (updated/unchanged)
				const currentMeta = params.data.metas[foundInCurrentData];

				if (meta.key != currentMeta.key || meta.value != currentMeta.value) {
					changedMeta.push(currentMeta);
				}
			}
		});
	}

	let promises = [];

	promises = [...promises, ...newMeta.map((meta) => {
		return httpClient(`${apiUrl}/${resource}/${params.id}/meta`, {
			method: 'POST',
			body: JSON.stringify(meta),
		});
	})];

	promises = [...promises, ...changedMeta.map((meta) => {
		return httpClient(`${apiUrl}/${resource}/${params.id}/meta/${meta.id}`, {
			method: 'PUT',
			body: JSON.stringify(meta),
		});
	})];

	promises = [...promises, ...deletedMeta.map((meta) => {
		return httpClient(`${apiUrl}/${resource}/${params.id}/meta/${meta.id}`, {
			method: 'DELETE',
		});
	})];

	if (promises.length > 0) {
		await Promise.all(promises);
	}

	resolve(1);
});
