import { httpClient } from "lib/http";
import { getApiUrl } from 'lib/env';

const apiUrl = `${getApiUrl()}api/v1`;

export const handleProducts = (resource, params) => new Promise(async (resolve) => {
	let newProducts = [];
	let changedProducts = [];
	let deletedProducts = [];

	if (params.data?.products != null) {
		newProducts = params.data.products.filter(
			p => p.id == null
		);
	}

	if (params.data?.products != null && params.previousData?.products != null) {
		params.previousData.products.forEach((product) => {
			const foundInCurrentData = params.data.products.findIndex((currentProduct) =>
				currentProduct.id == product.id
			);

			if (foundInCurrentData == -1) {
				// This product object isnt in current data (deleted)
				deletedProducts.push(product);
			} else {
				// This product still exists (updated/unchanged)
				const currentProduct = params.data.products[foundInCurrentData];

				if (product.key != currentProduct.key || product.value != currentProduct.value) {
					changedProducts.push(currentProduct);
				}
			}
		});
	}

	let promises = [];

	promises = [...promises, ...newProducts.map((product) => {
		return httpClient(`${apiUrl}/${resource}/${params.id}/products`, {
			method: 'POST',
			body: JSON.stringify(product),
		});
	})];

	promises = [...promises, ...changedProducts.map((product) => {
		return httpClient(`${apiUrl}/${resource}/${params.id}/products/${product.id}`, {
			method: 'PUT',
			body: JSON.stringify(product),
		});
	})];

	promises = [...promises, ...deletedProducts.map((product) => {
		return httpClient(`${apiUrl}/${resource}/${params.id}/products/${product.id}`, {
			method: 'DELETE',
		});
	})];

	if (promises.length > 0) {
		await Promise.all(promises);
	}

	resolve(1);
});
