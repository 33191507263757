import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import getVariant, { variants } from '../_variants';

const Button = ({
	children,
	variant,
	disabled,
	className,
	...props
}) => {
	return (
		<button
			className={cx(
				'flex',
				'items-center',
				'text-sm',
				'font-semibold',
				'py-4',
				'px-6',
				'rounded-full',
				'no-underline',
				'whitespace-nowrap',
				'justify-center',
				getVariant(variant),
				className,
				{
					'opacity-70': disabled,
					'cursor-not-allowed': disabled,
					'pointer-events-none': disabled,
				}
			)}
			{...props}
		>
			{children}
		</button>
	);
}

Button.defaultProps = {
	className: '',
	variant: 'green',
	disabled: false,
};

Button.propTypes = {
	className: PropTypes.string,
	disabled: PropTypes.bool,
	variant: PropTypes.oneOf(Object.keys(variants)),
};

export default Button;