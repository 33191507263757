import { List, Pagination as AdminPagination } from 'react-admin';

export const Pagination = (props) => {
	return (
		<AdminPagination
			rowsPerPageOptions={[10, 25, 50]}
			{...props}
		/>
	);
};

export const PaginatedList = (props) => {
	return (
		<List
			pagination={<Pagination />}
			{...props}
		/>
	);
};
