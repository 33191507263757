import cx from 'classnames';
import PropTypes from 'prop-types';

import './_skeleton.css';

const Skeleton = ({ className, size }) => {

	return (
		<div
			className={cx(
				size,
				'bg-gray-200',
				'c--skeleton',
				className,
			)}
			dangerouslySetInnerHTML={{ __html: '&nbsp'}}
		/>
	);

};

Skeleton.defaultProps = {
	className: '',
	size: 'text-base',
};

Skeleton.propTypes = {
	className: PropTypes.string,
	size: PropTypes.oneOf([
		'text-xs',
		'text-sm',
		'text-base',
		'text-lg',
		'text-xl',
		'text-2xl',
		'text-3xl',
		'text-4xl',
		'text-5xl',
		'text-6xl',
		'text-7xl',
		'text-8xl',
		'text-9xl',
	]),
};

export default Skeleton;
