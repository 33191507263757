import {
	ArrayInput,
	SimpleFormIterator,
	TextInput,
	SelectInput,
	NumberInput,
	BooleanInput,
	FormDataConsumer
} from 'react-admin';

export const ProductsInput = () => (
	<ArrayInput source="products" label="Price list">
		<SimpleFormIterator>
			<TextInput source="vat" defaultValue="0.25" disabled sx={{ display: 'none' }} />
			<NumberInput source="price" defaultValue="0" />

			<BooleanInput source="recurring" defaultValue={false} />

			<FormDataConsumer>
				{({ formData, scopedFormData, getSource, ...rest }) => scopedFormData.recurring &&
					<>
						<SelectInput
							source="subscriptionPeriod"
							label="Payment duration"
							defaultValue="6"
							emptyValue="6"
							emptyText="6 Months"
							choices={[
								{ id: '12', name: '12 Months' },
								{ id: '24', name: '24 Months' },
							]}
						/>

						<NumberInput source="cancellationMonths" defaultValue="0" />

						<BooleanInput source="automaticallyRenewal" defaultValue={false} />
					</>
				}
			</FormDataConsumer>

			<SelectInput
				source="currency"
				defaultValue="SEK"
				emptyValue="SEK"
				emptyText="SEK"
				choices={[]}
			/>

		</SimpleFormIterator>
	</ArrayInput>
);
