import jwt_decode from 'jwt-decode';
import { fetchUtils } from 'react-admin';
import { getApiUrl } from 'lib/env';

const httpClient = (url, options = {}) => {
	if (!options.headers) {
		options.headers = new Headers({ Accept: 'application/json' });
	}

	return fetchUtils.fetchJson(url, options);
};

const authProvider = {
	createLogin: (params) => {
		return httpClient(`${getApiUrl()}api/v2.0/users/login?type=bankid`, {
			method: 'POST',
			body: JSON.stringify(params),
		});
	},
	getLoginStatus: (ref) => {
		return httpClient(`${getApiUrl()}api/v2.0/users/auth/status/${ref}`, {
			method: 'GET',
		});
	},
	login: (token) => {
		localStorage.setItem('auth', token);
		return Promise.resolve();
	},
	// called when the user clicks on the logout button
	logout: () => {
		localStorage.removeItem('auth');
		return Promise.resolve();
	},
	// called when the API returns an error
	checkError: ({ status }) => {
		// if (status === 401 || status === 403) {
		//     return Promise.reject();
		// }
		return Promise.resolve();
	},
	// called when the user navigates to a new location, to check for authentication
	checkAuth: () => {
		const token_store = localStorage.getItem('auth');

		if (token_store == null) {
			return Promise.reject()
		}

		const token = jwt_decode(token_store);
		if (token.exp * 1000 < new Date().getTime()) {
			// Token invalid
			localStorage.removeItem('auth');
			return Promise.reject();
		} else {
			return Promise.resolve();
		}
	},
	// called when the user navigates to a new location, to check for permissions / roles
	getPermissions: () => Promise.resolve(),
};

export default authProvider;