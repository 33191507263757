import React, { useEffect, useState } from 'react';
import { useRedirect, useNotify } from 'react-admin';
import authProvider from 'providers/authProvider';
import { getDeviceType } from 'lib/agent';
import { Button } from 'UI';

import QRCode from "react-qr-code";

const LoginPage = () => {
	const [qrData, setQRData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [userHint, setUserHint] = useState(null);
	const notify = useNotify();
	const redirect = useRedirect();

	const STATUS_DELAY = 1500;
	var interval = null;

	useEffect(() => {
		if (getDeviceType() == 'iOS' || getDeviceType() == 'Android' || getDeviceType() == 'Windows Phone') {
			// create login
		}

		return () => clearInterval(interval);
	}, []);

	const checkLoginStatus = (reference) => {
		authProvider.getLoginStatus(reference).then((response) => {
			const { status, qrData, jwt } = response.json;

			setUserHint(getUserHint(response.json));

			if (status == 'pending') {
				// Still pending
				setQRData(qrData);
				return false;
			}

			setLoading(false);
			setQRData(null);
			clearInterval(interval);

			if (status == 'complete') {
				notify('Inloggning lyckades!');
				authProvider.login(jwt);
				redirect('/');
				return true;
			}

			// error
			console.log('Error response', response.json);
			notify('Inloggning misslyckades');
		});
	};

	const onTriggerLogin = (event) => {
		if (event != null) {
			event.preventDefault();
		}

		setLoading(true);

		authProvider.createLogin({}).then((response) => {
			console.log('login response', response);
			setUserHint(getUserHint(response.json));
			setQRData(response.json.qrData);

			interval = setInterval(() =>
				checkLoginStatus(response.json.orderRef),
			STATUS_DELAY);
		});
	};

	const getUserHint = (data) => {
		if (data == null || data.status == 'complete') {
			return null;
		}

		if (data.errorCode != null) {
			if (data.errorCode == 'cancelled') {
				return 'Åtgärden avbruten. Försök igen';
			}

			if (data.errorCode == 'alreadyInProgress') {
				return 'En identifiering eller underskrift för det här personnumret är redan börjad. Försök igen.';
			}

			if (data.errorCode == 'requestTimeout' || data.errorCode == 'maintenance' || data.errorCode == 'internalError') {
				return 'Intern tekniskt fel. Försök igen.';
			}
		}

		if (data.status == 'pending') {
			if (data.hintCode == 'noClient' || data.hintCode == 'outstandingTransaction') {
				return 'Starta BankID-appen';
			}

			if (data.hintCode == 'userSign') {
				return 'Skriv in din säkerhetskod i BankID-appen och välj Identifiera eller Skriv under.';
			}
		}

		if (data.status == 'failed') {
			if (data.hintCode == 'userCancel') {
				return 'Åtgärden avbruten.';
			}

			if (data.hintCode == 'expiredTransaction') {
				return 'BankID-appen svarar inte. Kontrollera att den är startad och att du har internetanslutning. Om du inte har något giltigt BankID kan du hämta ett hos din bank. Försök sedan igen.';
			}

			if (data.hintCode == 'startFailed') {
				return 'Intern tekniskt fel. Försök igen.';
			}
		}
	};

	return (
		<div className="flex items-center justify-center h-screen">
			<div className="w-1/4 mx-auto">
				<div className="bg-green p-6 rounded-md mb-9">
					<img className="h-12 mx-auto" src={`/assets/logo-transparent.png`} alt="Strolly logotype" />
				</div>

				<div className="mb-4 text-center">
					<h3 className="text-2xl">Välj inloggningsmetod</h3>
				</div>

				<Button onClick={onTriggerLogin} className="w-full mb-2" disabled={loading}>
					Bank-ID på annan enhet
				</Button>

				{qrData != null ?
					<div className="mt-12 mx-auto border border-gray-700 p-6 rounded-lg w-50 h-50">
						<div style={{ height: "auto", margin: "0 auto", maxWidth: 128, width: "100%" }}>
							<QRCode
								size={500}
								style={{ height: "auto", maxWidth: "100%", width: "100%" }}
								value={qrData}
								viewBox={`0 0 500 500`}
							/>
						</div>

						<div className="text-center">
							{userHint}
						</div>
					</div>
					: null}
			</div>
		</div>
	);

};

export default LoginPage;