import { httpClient } from 'lib/http';
import { stringify } from 'query-string';
import { getApiUrl } from 'lib/env';
import { uploadImages, deleteImages } from './imageProvider';
import { handleMeta } from './metaProvider';
import { handleProducts } from './productsProvider';

const apiUrl = `${getApiUrl()}api/v1`;

const resourceFilter = (resource = '', params) => {
	if (resource == 'accessories') {
		return 'items';
	}

	return resource;
};

const dataProvider = {
	getList: (resource, params) => {
		resource = resourceFilter(resource, params);

		const { page, perPage } = params.pagination;
		const { field, order } = params.sort;
		const query = {
			sort: JSON.stringify([field, order]),
			range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
			filter: JSON.stringify(params.filter),
			page: page,
			size: perPage,
		};

		const url = `${apiUrl}/${resource}?${stringify(query)}`;

		return httpClient(url).then(({ headers, json }) => ({
			data: json.data,
			total: json.total,
		}));
	},

	getOne: (resource, params) => {
		resource = resourceFilter(resource, params);

		return httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
			data: json,
		}));
	},

	getMany: (resource, params) => {
		resource = resourceFilter(resource, params);

		const query = {
			// filter: JSON.stringify({ id: params.ids }),
			page: 1,
			size: 99,
		};

		const url = `${apiUrl}/${resource}?${stringify(query)}`;

		return httpClient(url).then(({ json }) => ({
			data: json.data,
		}));
	},

	getManyReference: (resource, params) => {
		resource = resourceFilter(resource, params);

		// const { page, perPage } = params.pagination;
		const { field, order } = params.sort;
		const query = {
			sort: JSON.stringify([field, order]),
			// range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
			// filter: JSON.stringify({
			// 	...params.filter,
			// 	[params.target]: params.id,
			// }),
			page: 1,
			size: 99,
		};

		const url = `${apiUrl}/${resource}?${stringify(query)}`;

		return httpClient(url).then(({ headers, json }) => ({
			data: json.data,
			total: json.total,
		}));
	},

	update: async (resource, params) => {
		resource = resourceFilter(resource, params);

		await handleMeta(resource, params);
		await handleProducts(resource, params);

		// Upload new images from `images` property in params data. (if any)
		await uploadImages(resource, params);

		// Deletes removed images from `images` property in params data. (if any)
		await deleteImages(resource, params);

		return httpClient(`${apiUrl}/${resource}/${params.id}`, {
			method: 'PUT',
			body: JSON.stringify(params.data),
		}).then(({ json }) => ({ data: json }))
	},

	updateMany: (resource, params) => {
		resource = resourceFilter(resource, params);

		const query = {
			filter: JSON.stringify({ id: params.ids }),
		};

		return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
			method: 'PUT',
			body: JSON.stringify(params.data),
		}).then(({ json }) => ({ data: json }));
	},

	create: (resource, params) => {
		resource = resourceFilter(resource, params);

		return httpClient(`${apiUrl}/${resource}`, {
			method: 'POST',
			body: JSON.stringify({
				...params.data,
				images: [],
			}),
		}).then(async ({ json }) => {
			await handleMeta(resource, {
				...params,
				id: json.id
			});

			await handleProducts(resource, {
				...params,
				id: json.id
			});

			// Upload new images from `images` property in params data. (if any)
			await uploadImages(resource, {
				...params,
				id: json.id
			});

			return {
				data: json,
			};
		});
	},

	delete: (resource, params) => {
		resource = resourceFilter(resource, params);

		return httpClient(`${apiUrl}/${resource}/${params.id}`, {
			method: 'DELETE',
		}).then(({ json }) => ({ data: json }));
	},

	deleteMany: (resource, params) => {
		resource = resourceFilter(resource, params);

		const promises = params.ids.map((id) => {
			return httpClient(`${apiUrl}/${resource}/${id}`, {
				method: 'DELETE',
			});
		});

		return Promise.all(promises)
			.then(() => ({
				data: [],
				total: 0,
			}))
			.catch(() => 0);
	},
};

export default dataProvider;