import {
	ArrayInput,
	BooleanInput,
	SimpleFormIterator,
	TextInput,
	SelectInput,
	FormDataConsumer,
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';

export const MetaInput = () => (
	<ArrayInput source="metas" label="Product content">
		<SimpleFormIterator>
			<TextInput
				source="type"
				defaultValue="HTML"
				disabled
				sx={{ display: 'none' }}
			/>

			<FormDataConsumer>
				{({ formData, scopedFormData, getSource, ...rest }) => scopedFormData.advanced ?
					<TextInput
						source={getSource('key')}
						fullWidth
						{...rest}
					/>
					:
					<SelectInput
						source={getSource('key')}
						choices={[
							{ id: '_meta_body', name: 'Product body text' },
							{ id: '_meta_description', name: 'Product description' },
							{ id: '_meta_specifications_dimensions_weight', name: 'Product weight' },
							{ id: '_meta_specifications_dimensions_max_weight', name: 'Product recommended max weight' },
							{ id: '_meta_specifications_dimensions', name: 'Product dimensions' },
							{ id: '_meta_specifications_dimensions_folded', name: 'Product dimensions (folded)' },
							{ id: '_meta_documents', name: 'Product documents' },
						]}
						fullWidth
						{...rest}
					/>
				}
			</FormDataConsumer>

			<RichTextInput source="value" fullWidth />

			<BooleanInput label="Advanced mode" source="advanced" />
		</SimpleFormIterator>
	</ArrayInput>
);
