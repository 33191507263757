import cx from 'classnames';

const Tag = ({ className, active, ...props }) => {

	return (
		<div
			className={cx(
				"px-4",
				"py-3",
				"mr-2",
				"mb-2",
				"rounded",
				"transition",
				"ease-in-out",
				"cursor-pointer",
				"bg-gray-200",
				"hover:bg-gray-300",
				"last-of-type:mr-0",
				"border",
				"border-transparent",
				className,
				{
					'border-green': active,
				}
			)}
			{...props}
		/>
	);

};

Tag.defaultProps = {
	className: '',
	active: false,
};

export default Tag;