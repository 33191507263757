import { httpClient } from "lib/http";
import { getApiUrl } from 'lib/env';

const apiUrl = `${getApiUrl()}api/v1`;

export const uploadImages = (resource, params) => new Promise(async (resolve) => {
	let newImages = [];
	if (params.data?.images != null) {
		newImages = params.data.images.filter(
			p => p.rawFile instanceof File
		);
	}

	const imagePromises = newImages.map((image) => {
		const formData = new FormData();
		formData.append('file', image.rawFile);

		return httpClient(`${apiUrl}/${resource}/${params.id}/images`, {
			method: 'POST',
			body: formData,
		});
	});

	if (imagePromises.length > 0) {
		// Upload new images
		await Promise.all(imagePromises);
	}

	resolve(1);
});

export const deleteImages = (resource, params) => new Promise(async (resolve) => {
	let deletedImages = [];

	if (params.data?.images != null && params.previousData?.images != null) {
		params.previousData.images.forEach((image) => {
			const foundInCurrentData = params.data.images.findIndex((currentImage) =>
				currentImage.id == image.id
			) > -1;

			if (! foundInCurrentData) {
				deletedImages.push(image);
			}
		});
	}

	const imagePromises = deletedImages.map((image) =>
		httpClient(`${apiUrl}/${resource}/${params.id}/images/${image.id}`, {
			method: 'DELETE',
		})
	);

	if (imagePromises.length > 0) {
		await Promise.all(imagePromises);
	}

	resolve(1);
});
