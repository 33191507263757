export const variants = {
	'green': 'text-white bg-green',
	'green-outline': 'text-green border border-green',
	'black': 'text-white bg-black',
	'black-outline': 'text-black border border-black',
}

const getVariant = (variant) => {
	return variants[variant] || '';
}

export default getVariant;