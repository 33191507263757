import {
	Datagrid,
	TextField,
	TextInput,
	SimpleForm,
	Edit,
	Create,
	EditButton,
	BooleanField,
	BooleanInput,
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import { PaginatedList } from './Pagination';
import { ImageInput } from './ImageInput';

const BrandForm = () => (
	<SimpleForm>
		<BooleanInput label="Partner" source="partner" />
		<TextInput label="Name" source="name" fullWidth />
		<RichTextInput source="description" fullWidth />

		<ImageInput />
	</SimpleForm>
);

export const BrandEditForm = () => (
	<Edit redirect={false}>
		<BrandForm />
	</Edit>
);

export const BrandCreateForm = () => (
	<Create>
		<BrandForm />
	</Create>
);

export const BrandsList = () => (
	<PaginatedList title="Brands list">
		<Datagrid>
			<TextField source="id" />
			<TextField source="name" />
			<TextField label="Available strollers" source="totalAvailableStrollers" />
			<BooleanField label="Partner" source="partner" />
			<EditButton />
		</Datagrid>
	</PaginatedList>
);