import { Admin, Notification, Resource } from 'react-admin';
import dataProvider from 'providers/dataProvider';
import authProvider from 'providers/authProvider';

import LoginPage from './pages/LoginPage'

import { StrollerList } from 'components/Strollers'
import { AccessoryCreateForm, AccessoryEditForm, AccessoriesList } from 'components/Accessories';
import { BrandsList, BrandEditForm, BrandCreateForm } from 'components/Brands';

const App = () => (
	<Admin authProvider={authProvider} loginPage={LoginPage} dataProvider={dataProvider}>
		<Notification autoHideDuration={15 * 1000} />

		<Resource
			name="accessories"
			list={AccessoriesList}
			edit={AccessoryEditForm}
			create={AccessoryCreateForm}
			recordRepresentation={(record) => record.title}
		/>

		<Resource
			name="brands"
			list={BrandsList}
			edit={BrandEditForm}
			create={BrandCreateForm}
			recordRepresentation={(record) => record.name}
		/>

		<Resource
			name="strollers"
			list={StrollerList}
		/>
	</Admin>
);

export default App;
